/*
 * @Author: Chance Lau
 * @Date: 2023-05-08 13:02:56
 * @LastEditors: Chance Lau
 * @LastEditTime: 2023-10-17 11:55:54
 * @Description: file content
 */
export const avatarUrlDefault =
  'https://saas-material-1252497236.cos.ap-beijing.myqcloud.com/cllect-mobile/identity/shenfen3.png'
/*身份认证
 */
export const realNameAuthStateMap: KeyVal = {
  0: '未认证',
  2: '认证成功',
  3: '认证失败',
}
export const bankCardAuthStateMap: KeyVal = {
  0: '未认证',
  2: '认证成功',
  3: '认证失败',
}
/*医百认证
 */
export const ybAuthStateMap: KeyVal = {
  0: '未认证',
  1: '审核中',
  2: '认证成功',
  3: '认证失败',
}

export const identityTypeList = [
  { id: 1, name: '医生' },
  { id: 2, name: '护士' },
  { id: 3, name: '药师' },
  { id: 4, name: '技师' },
  // { id: 5, name: '企业人员' },
  { id: 6, name: '药店人员' },
  { id: 9, name: '市场人员' },
  { id: 0, name: '未知' },
]
export const unAuthIdentityTypeList = [
  { id: 1, name: '医生' },
  { id: 2, name: '护士' },
  { id: 3, name: '药师' },
]

<!--
 * @Author: Chance Lau
 * @Date: 2023-05-08 13:02:56
 * @LastEditors: Chance Lau
 * @LastEditTime: 2023-10-27 15:20:44
 * @Description: file content
-->
<template>
  <div class="wrapper">
    <!--    左侧菜单-->
    <div class="app-aside">
      <Sidebar />
    </div>
    <div class="app-container">
      <!--      顶部header-->
      <div class="app-header">
        <Levelbar />
        <PersonalCenter />
      </div>
      <!--      主要内容-->
      <el-scrollbar class="app-main">
        <router-view />
      </el-scrollbar>
    </div>
  </div>
</template>
<script lang="ts">
import Sidebar from './Sidebar.vue'
import PersonalCenter from './PersonalCenter.vue'
import Levelbar from './Levelbar.vue'

export default defineComponent({
  components: {
    Levelbar,
    PersonalCenter,
    Sidebar,
  },
})
</script>
<style lang="scss" scoped>
.wrapper {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;

  .app-aside {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    width: 200px;
    background: rgba(245, 245, 245, 1);
  }

  .app-container {
    position: absolute;
    left: 200px;
    right: 0;
    top: 0;
    bottom: 0;

    .app-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 50px;
      box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
      background: #fff;
    }

    .app-main {
      width: 100%;
      height: 100%;
      overflow: auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 50px 20px 0;
      background: #ffffff;
    }
  }
}
</style>

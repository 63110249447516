import { defineStore, acceptHMRUpdate } from 'pinia'
const STORE_KEY = import.meta.env.VITE_APP_NAME + '-PERMISSION'
import permApi from '@/api/permission'
import { allRoutes } from '@/router/index'

export const usePermissionStore = defineStore({
  id: STORE_KEY,
  state: (): Permission => ({
    routes: [],
    auths: {},
  }),
  getters: {
    getRoutes: (state: Permission) => {
      return state.routes || []
    },
    getAuths: (state: Permission) => {
      return state.auths || {}
    },
  },
  actions: {
    async initRoutesAsync() {
      const data = await requestPermissionAsync()
      if (!data) return []
      //匹配权限路由
      const flatRoutes = flatRouteTree(allRoutes)
      this.routes = matchRoutes(data, flatRoutes)
      //生成按钮权限
      this.auths = generateButtonAuth(data)
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: sessionStorage,
      },
    ],
  },
})

function generateButtonAuth(data: any) {
  const btnAuths: Partial<any> = {}
  const getButtonAuthLoop = (arr: any) => {
    arr?.forEach((menu: any) => {
      menu?.buttons?.forEach((btn: any) => {
        btnAuths[btn.front] = true
      })
      if (menu.childs?.length) {
        getButtonAuthLoop(menu.childs)
      }
    })
  }
  getButtonAuthLoop(data)
  return btnAuths
}

//#region 匹配权限路由
function flatRouteTree(routes: Array<any>, flatRoutes?: any) {
  const _flatRoutes = flatRoutes || new Array<any>()
  routes.forEach(r => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const { children, component, components, ...route } = r
    _flatRoutes.push(r)
    if (r.children) {
      flatRouteTree(r.children, _flatRoutes)
    }
  })
  return _flatRoutes
}
async function requestPermissionAsync() {
  try {
    const res = await permApi.getAdminMenus()
    // const res = await permApi.getPermission()
    return res?.data
  } catch (err: any) {
    console.error(err)
    return null
  }
}

function matchRoutes(permList: Array<any>, appRoutes: Array<any>) {
  const routes = new Array<any>()
  permList
    .sort((pre: any, cur: any) => Number(pre.sort) - Number(cur.sort))
    .forEach((perm: any) => {
      const route = appRoutes.find((r: any) => r.sign === perm.front)
      if (route) {
        route.meta = route.meta || {}
        route.meta.title = perm.label
        let child = null
        if (perm.childs) {
          child = matchRoutes(perm.childs, appRoutes)
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { children, component, components, ...r } = route
        routes.push({
          ...r,
          children: child,
        })
      }
    })
  return routes
}
//#endregion
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePermissionStore, import.meta.hot))
}
